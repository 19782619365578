import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const PostCollection = ({ data, location }) => {
	const page = data.ghostPage;
	const postList = data.allGhostPost;

	return (
		<Fragment>
			<MetaData
				data={data}
				location={location}
				type="website"
			/>
			<Helmet>
				<style type="text/css">{`${page.codeinjection_styles}`}</style>
			</Helmet>
			<Layout>
				<div className="container">
					<article className="content">
						<section className="collection-content">
							<h1 className="content-title">{page.title}</h1>
							{postList.edges.length > 0 &&
							<Fragment>
								<ol className="collection-list">
									{ postList.edges.map((node, i) => (
										<li key={`post-list ${i}`}>
											<a href={`${location.pathname.replace(/\/$/, ``)}/${node.node.slug}`}>{node.node.title}</a>
										</li>
									))
									}
								</ol>
							</Fragment>
							}

							{/* The main page content */}
							<section
								className="content-body load-external-scripts"
								dangerouslySetInnerHTML={{ __html: page.html }}
							/>
						</section>
					</article>
				</div>
			</Layout>
		</Fragment>
	);
};

PostCollection.propTypes = {
	data: PropTypes.shape({
		allGhostPost: PropTypes.shape({
			edges: PropTypes.array,
		}),
		ghostPage: PropTypes.shape({
			codeinjection_styles: PropTypes.object,
			title: PropTypes.string.isRequired,
			html: PropTypes.string,
			feature_image: PropTypes.string,
		}).isRequired,
	}).isRequired,
	location: PropTypes.object.isRequired,
};

export default PostCollection;

export const postQuery = graphql`
    query($slug: String!) {
			ghostPage(slug: {eq: $slug}) {
				...GhostPageFields
			}
			allGhostPost(sort: {order: DESC, fields: published_at}, filter: {primary_tag: {slug: {eq: $slug}}}) {
		    edges {
		      node {
		        slug
		        tags {
		          slug
		        }
		        primary_tag {
		          slug
		        }
						title
		      }
		    }
		  }
    }
`;
